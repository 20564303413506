<template>
  <ion-page>
    <ion-header>

      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="../home"></ion-back-button>
        </ion-buttons>
        <ion-title>Book Details</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Book Information</ion-card-subtitle>
          <ion-card-title><ion-skeleton-text v-if="!title" animated></ion-skeleton-text>{{ title }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-badge v-if="isAvailable" color="tertiary">Available</ion-badge>
          <ion-badge v-else color="dark">Unavailable</ion-badge>
          <ion-grid>
            <ion-row>
              <ion-col size="4">
                <strong>Author</strong>
              </ion-col>
              <ion-col >
                <span v-if="author === 'NaN'"><i>Author information unavailable</i></span>
                <span v-else>{{ author }}</span>
                <span><ion-skeleton-text v-if="!author" animated></ion-skeleton-text></span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4">
                <strong>Publisher</strong>
              </ion-col>
              <ion-col >
                <span v-if="publisher === 'NaN'"><i>Publisher information unavailable</i></span>
                <span v-else>{{ publisher }}</span>
                <span><ion-skeleton-text v-if="!publisher" animated></ion-skeleton-text></span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4">
                <strong>Year</strong>
              </ion-col>
              <ion-col >
                <span v-if="year === 'NaN'"><i>Year information unavailable</i></span>
                <span v-else>{{ year }}</span>
                <span><ion-skeleton-text v-if="!year" animated></ion-skeleton-text></span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4">
                <strong>ISBN</strong>
              </ion-col>
              <ion-col >
                <span v-if="isbn === 'NaN'"><i>ISBN unavailable</i></span>
                <span v-else>{{ isbn }}</span>
                <span><ion-skeleton-text v-if="!isbn" animated></ion-skeleton-text></span>
              </ion-col>
            </ion-row>
            <ion-row v-if="otherRemarks !== 'NaN'">
              <ion-col size="4">
                <strong>Remarks</strong>
              </ion-col>
              <ion-col >
                <i>{{ otherRemarks }}</i>
                <span><ion-skeleton-text v-if="!otherRemarks" animated></ion-skeleton-text></span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Lending Information</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <ion-badge v-if="lentTo !== 'NaN'" color="warning">On Loan</ion-badge>
          <div v-else-if="rtl === 'No'">
            <ion-badge color="warning">Not Ready To Lend</ion-badge>
          </div>
          <div v-else-if="reservedBy !== 'NaN'">
            <ion-badge color="warning">Reserved</ion-badge>
          </div>
          <div v-else-if="availabilityStatus == 'Donated'">
            <ion-badge color="medium">Donated</ion-badge>
          </div>
          <div v-else>
            <ion-badge color="tertiary">Ready To Lend</ion-badge>
          </div>

          <ion-grid>
            <ion-row>
              <ion-col size="4">
                <strong>Location</strong>
              </ion-col>
              <ion-col>
                {{ bookLocation }}
                <span><ion-skeleton-text v-if="!bookLocation" animated></ion-skeleton-text></span>
              </ion-col>
            </ion-row>
            <div v-if="lentTo !== 'NaN'">
              <ion-row>
                <ion-col size="4">
                  <strong>Loanee</strong>
                </ion-col>
                <ion-col>
                  {{ lentTo }}
                  <span><ion-skeleton-text v-if="!lentTo" animated></ion-skeleton-text></span>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="4">
                  <strong>Lent On</strong>
                </ion-col>
                <ion-col>
                  {{ lentOn }}
                  <span><ion-skeleton-text v-if="!lentOn" animated></ion-skeleton-text></span>
                </ion-col>
              </ion-row>
            </div>
            <ion-row v-if="reservedBy !== 'NaN'">
              <ion-col size="4">
                <strong>Reserved By</strong>
              </ion-col>
              <ion-col >
                {{ reservedBy }}
                <span><ion-skeleton-text v-if="!reservedBy" animated></ion-skeleton-text></span>
              </ion-col>
            </ion-row>
            <ion-row v-if="rtlRemarks !== 'NaN'">
              <ion-col size="4">
                <strong>Remarks</strong>
              </ion-col>
              <ion-col >
                <i>{{ rtlRemarks }}</i>
                <span><ion-skeleton-text v-if="!rtlRemarks" animated></ion-skeleton-text></span>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button :href="'https://wa.me/917568942654?text=' + whatsAppText()" :disabled="isAvailable? 'false': 'true'" expand="block">Borrow This Book</ion-button>
          <br/>
          <i><p>Clicking above button will open WhatsApp. You can alternatively <a href="sms:+917568942654">contact me via SMS</a> or <a href="mailto:mohitdhanjani@protonmail.com">email</a>.</p></i>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Lending Notes, Terms and Conditions</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <h2>It's about making the world a better place by ensuring that knowledge is accessible. But while I would love to share my collection with you, there are reasons to be cautious too as these books cost a lot. Hence, these terms and conditons.</h2>
          <br/>
          <h3>On Lending</h3>
          <ul>
            <li>I will be lending you the book <strong>free of cost</strong>. But you can certainly buy me a coffee and we
              can discuss about the book. ☕️</li>
            <li>All the books listed on this website are part of my personal collection. For this reason I am very selective in regard to potential loanee.</li>
            <li>Please request for books if you are pursing MPhil or PhD, or if you have dissertation in your <strong>current</strong> semester as part of your undergraduate/postgraduate degree course. 👩🏽‍🎓</li>
            <li>You have to make your own arrangements to get the book from my location. I will not deliver them to you. 🚌</li>
            <li>If you are not a student or researcher, and do not hold PhD, you can still request for the books, but
            it will be harder for me to consider your request.</li>
            <li>You are free to contact me on <a href="tel:+917568942654">my mobile</a> or <a href="mailto:mohitdhanjani@protonmail.com">email</a> if you have any question. 🙋🏽</li>
          </ul>
          <h3>On Returns</h3>
          <ul>
            <li>You have a moral obligation to return the book on or before due date.</li>
            <li>If you fail to return, then you will lose access to my personal collection (<i>and shame on you for not returning ☹️)</i>.</li>
          </ul>
        </ion-card-content>
      </ion-card>

    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonGrid, IonRow, IonCol, IonButton, IonBadge, IonButtons, IonSkeletonText } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Book',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonCard,
    IonCardSubtitle,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonBadge,
    IonButtons,
    IonSkeletonText
  },
  //props: ['Key','PublicationYear','Author','Title','ISBN','Publisher','RTL','RTLRemarks','AvailabilityStatus','LentTo','LentOn','OtherRemarks','BookLocation','ReservedBy','ReservedOn'],
  data:() => ({
    title: null,//bookInfo.Title,
    author: null, //bookInfo.Author,
    publisher: null, //bookInfo.Publisher,
    year: null, //bookInfo.PublicationYear,
    rtlRemarks: null, //bookInfo.RTLRemarks,
    otherRemarks: null, //bookInfo.OtherRemarks,
    isbn: null, //bookInfo.ISBN,
    availabilityStatus: null, //bookInfo.AvailabilityStatus,
    rtl: null, //bookInfo.RTL,
    reservedBy: null, //bookInfo.ReservedBy,
    reservedOn: null, //bookInfo.ReservedOn,
    lentTo: null, //bookInfo.LentTo,
    lentOn: null, //bookInfo.LentOn,
    bookLocation: null //bookInfo.BookLocation,
  }),
  created() {
    let bookKey = this.$route.params.bookID;
    if(sessionStorage.getItem(bookKey) != null) {
      let bookDetails = JSON.parse(sessionStorage.getItem(bookKey));
      this.key = bookDetails.Key;
      this.year = bookDetails.PublicationYear;
      this.title = bookDetails.Title;
      this.author = bookDetails.Author;
      this.isbn = bookDetails.ISBN;
      this.publisher = bookDetails.Publisher;
      this.rtl = bookDetails.RTL;
      this.rtlRemarks = bookDetails.RTLRemarks;
      this.availabilityStatus = bookDetails.AvailabilityStatus;
      this.lentTo = bookDetails.LentTo;
      this.lentOn = bookDetails.LentOn;
      this.otherRemarks = bookDetails.OtherRemarks;
      this.bookLocation = bookDetails.BookLocation;
      this.reservedBy = bookDetails.ReservedBy;
      this.reservedOn = bookDetails.ReservedOn;
    } else {
      fetch("https://sheets.googleapis.com/v4/spreadsheets/1J44gFMQqIRlGpiZsQe4nMMFBkbKVDizfAbcnH8gnixI/values/BooksStatus?key=AIzaSyByeMtfYjZ1_JkkKpW-_GXi5O72iqQxbnY")
          .then(response => response.json())
          .then(data => {
            data.values.forEach(book => {
              var bookDetails;
              bookDetails = {
                Key: book[0],
                PublicationYear: book[1],
                Author: book[2],
                Title: book[3],
                ISBN: book[4],
                Publisher: book[5],
                RTL: book[6],
                RTLRemarks: book[7],
                AvailabilityStatus: book[8],
                LentTo: book[9],
                LentOn: book[10],
                OtherRemarks: book[11],
                BookLocation: book[12],
                ReservedBy: book[13],
                ReservedOn: book[14]
              }
              sessionStorage.setItem(bookDetails.Key, JSON.stringify(bookDetails));
              if(book[0] == bookKey) {
                this.key = bookDetails.Key;
                this.year = bookDetails.PublicationYear;
                this.title = bookDetails.Title;
                this.author = bookDetails.Author;
                this.isbn = bookDetails.ISBN;
                this.publisher = bookDetails.Publisher;
                this.rtl = bookDetails.RTL;
                this.rtlRemarks = bookDetails.RTLRemarks;
                this.availabilityStatus = bookDetails.AvailabilityStatus;
                this.lentTo = bookDetails.LentTo;
                this.lentOn = bookDetails.LentOn;
                this.otherRemarks = bookDetails.OtherRemarks;
                this.bookLocation = bookDetails.BookLocation;
                this.reservedBy = bookDetails.ReservedBy;
                this.reservedOn = bookDetails.ReservedOn;
              }
            })
          });
  }
  },
  computed: {
    isAvailable() {
      if(this.availabilityStatus == "Donated") {
        return false;
      } else if(this.availabilityStatus !== 'No' && this.rtl !== 'No' && this.reservedBy === 'NaN') {
        return true;
      } else
      {
        return false;
      }
    }
  },
  methods: {
    whatsAppText() {
      return encodeURI("Hey, Mohit! I am interested in a book (" + this.title + ") from your personal library. Let's discuss about it and come to some agreement?");
    }
  }
});
</script>

<style>

</style>
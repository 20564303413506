import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
//import { UAParser } from 'ua-parser-js'

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
//import { isPlatform } from '@ionic/vue';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(IonicVue)
  .use(router);

/*var parser = new UAParser();
var browserName = parser.getBrowser().name;

if(isPlatform('android') && browserName !== "Chrome") {
  var r = confirm("It appears you are using a web browser which is not supported by this application. Please use Google Chrome.");
  if(r == true) {
    window.open('googlechrome://navigate?url=https://dazzling-saha-e1fd2b.netlify.app', "_self");
  }
}*/
  
router.isReady().then(() => {
  app.mount('#app');
});
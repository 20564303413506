<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>📚 MRD's Library</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">📚 Mohit R. Dhanjani's Library</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-card id="about">
        <ion-item>
          <ion-icon :icon="informationCircle" slot="start"></ion-icon>
          <ion-label>About This Library</ion-label>
          <ion-button @click="showAbout? showAbout = false: showAbout = true" color="dark" fill="outline" slot="end">{{ showAbout? 'Close' : 'Open' }}</ion-button>
        </ion-item>
        <ion-card-content v-show="showAbout">
          <p><strong>Hey! 👋🏼</strong></p>
          <p>This is online catalogue of books in my personal library.
            You are free to explore and request these books for research purposes.</p>
          <ion-label><br /></ion-label>
          <h3>Made with ♥️ by...</h3>
          <ion-chip @click="openInstagram" outline color="tertiary">
            <ion-icon :icon="logoInstagram"></ion-icon>
            <ion-label>Mohit R. Dhanjani <strong>@MRDcoin</strong></ion-label>
          </ion-chip>
          <ion-label><br /><br/></ion-label>
          <p>🎉 Built with and thanks to <a href="https://ionicframework.com" target="_blank">Ionic</a>, <a href="https://vuejs.org" target="_blank">Vue</a>, <a href="https://www.netlify.com" target="_blank">Netlify</a>, <a href="https://github.com" target="_blank">Github</a>, <a href="https://www.jetbrains.com" target="_blank">JetBrains</a>, and <a href="https://www.namecheap.com" target="_blank">Namecheap</a>.</p>
        </ion-card-content>
      </ion-card>

      <ion-card v-show="false">
        <ion-item color="danger">
          <ion-icon :icon="warning" slot="start"></ion-icon>
          <ion-label>Library Not Functional</ion-label>
        </ion-item>

        <ion-card-content>
          <p>
            This web-app makes use of data which is retrieved from Google Sheets in JSON format.
            <br /><br />
            However, due to some unknown reason, or probably changes made on the side of Google,
            the sheet in which the catalogue is maintained is returning error instead.
            <br />
          </p>
            <br />
          <p>I'm unable to work on this app for few reasons. But I will try to fix this issue once I get time.</p>
<br />
            <p><strong>I regret the inconvenience caused.</strong></p>
        </ion-card-content>
      </ion-card>

          <ion-list>
            <ion-searchbar v-model="searchVal" placeholder="Search by book title" disabled="false" show-cancel-button="focus" animate="true"></ion-searchbar>
            <ion-item v-if="books.length == 0">
              <ion-label>Loading Books</ion-label>
              <ion-spinner name="dots"></ion-spinner>
            </ion-item>
            <ion-item lines="full" v-for="book in searchBooks" v-bind:key="book" v-bind="book" :router-link="'/books/' +  book.Key" detail>
              <!--<ion-thumbnail slot="start">
                <img v-bind:src="'http://covers.openlibrary.org/b/isbn/' + book.ISBN.replaceAll('-', '') + '-S.jpg'">
              </ion-thumbnail>-->
              <ion-label>
                <h2>{{ book.Title }}</h2>
                <p v-if="book.Author !== 'NaN'">{{ book.Author }}</p>
                <p v-else><i>Author information unavailable</i></p>
              </ion-label>


              <ion-badge v-if="book.AvailabilityStatus == 'Donated'" color="medium">Donated</ion-badge>
              <ion-badge v-else-if="isAvailable(book)" color="tertiary">Available</ion-badge>
              <ion-badge v-else color="dark">Unavailable</ion-badge>
            </ion-item>
          </ion-list>

    </ion-content>

  </ion-page>
</template>

<script>
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonSearchbar, IonBadge, IonCardContent, IonCard, IonButton, IonIcon, IonChip, IonSpinner } from '@ionic/vue';
import { defineComponent } from 'vue';
import { informationCircle, logoVue, logoIonic, logoInstagram, warning } from 'ionicons/icons';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonSearchbar,
    IonBadge,
    IonCard,
    IonCardContent,
    IonButton,
    IonIcon,
    IonChip,
    IonSpinner
  },
  data: function() {
    return { books: [], searchVal: '', showAbout: true }

  },
  created() {

    fetch("https://sheets.googleapis.com/v4/spreadsheets/1J44gFMQqIRlGpiZsQe4nMMFBkbKVDizfAbcnH8gnixI/values/BooksStatus?key=AIzaSyByeMtfYjZ1_JkkKpW-_GXi5O72iqQxbnY")
        .then(response => response.json())
        .then(data => {
          data.values.forEach(book => {
            var bookItem = {
              Key: book[0],
              PublicationYear: book[1],
              Author: book[2],
              Title: book[3],
              ISBN: book[4],
              Publisher: book[5],
              RTL: book[6],
              RTLRemarks: book[7],
              AvailabilityStatus: book[8],
              LentTo: book[9],
              LentOn: book[10],
              OtherRemarks: book[11],
              BookLocation: book[12],
              ReservedBy: book[13],
              ReservedOn: book[14]
            };
            if(bookItem.Title != "Title"){
              sessionStorage.setItem(bookItem.Key, JSON.stringify(bookItem));
              this.books.push(bookItem);
            }

          })
        });

  },
  methods: {
    isAvailable(book) {
    if(book.AvailabilityStatus !== 'No' && book.RTL !== 'No' && book.ReservedBy === 'NaN') {
      return true;
    } else {
      return false;
    }
  },
    openInstagram() {
      window.open('https://instagram.com/mrdcoin', '_blank');
    },
  },
  computed: {
    searchBooks() {
      const bookDetails = this.$data.books;
      console.log("Here is the list.");
      console.log(bookDetails);
      //return bookDetails;
      const searchTerm = this.searchVal.toLowerCase();
      if(searchTerm == '') {
        return bookDetails;
      }
      else {
        var filteredItems = new Array();
        bookDetails.forEach(function(book){
          if(book.Title.toLowerCase().includes(searchTerm)){
            filteredItems.push(book);
          }
        });
        return filteredItems;
      }

    }
  },
  setup() {

    return { informationCircle, logoVue, logoIonic, logoInstagram, warning };
  }
});
</script>

<style scoped>

</style>